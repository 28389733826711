<template>
    <div class="taskExecution">
        <header>
            <div class="tabs">
                <el-tabs v-model="mainTab" @tab-click="mainTabClick" class="main-tab">
                    <el-tab-pane v-if="subMenu.to_be_evaluated" label="待评估" name="0"></el-tab-pane>
                    <el-tab-pane v-if="subMenu.appraisal" label="已评估" name="1"></el-tab-pane>
                </el-tabs>
            </div>
            <div class="searchs">
                <el-form :inline="true" :model="query" class="demo-form-inline">
                    <el-form-item label="问题名称：" prop="problemTitle">
                        <el-input placeholder="请输入" v-model="query.problemTitle"></el-input>
                    </el-form-item>
                    <el-form-item label="状态：" v-if="mainTab == '1'">
                        <el-select v-model="query.problemStatus" class="search-input" placeholder="请选择">
                            <el-option key="" label="全部" value="" />
                            <el-option
                                v-for="(item, index) in problemStatusMap"
                                :key="index"
                                :label="item"
                                :value="index"
                            />
                        </el-select>
                    </el-form-item>
                </el-form>
                <div class="btns">
                    <el-button @click="search" type="primary">查 询</el-button>
                    <el-button @click="resize" plain>重 置</el-button>
                </div>
            </div>
        </header>
        <main>
            <div class="subToolbar">
                <div class="title" v-if="mainTab == '0'">待评估列表</div>
                <div class="title" v-if="mainTab == '1'">已评估列表</div>
            </div>
            <div class="table">
                <el-table
                    :data="tableData"
                    :default-sort="{ prop: 'createTime', order: 'descending' }"
                    style="width: 100%; height: 100%"
                    header-row-class-name="table-header"
                    height="calc(100% - 40px)"
                    :header-cell-style="{
                        background: '#F7F8FA',
                        color: '#333333',
                        height: '54px',
                        'font-size': '14px',
                    }"
                    @cell-dblclick="dblclickRow"
                >
                    <!-- <el-table-column type="selection" width="55" /> -->
                    <el-table-column type="index" width="70" label="序号" />
                    <el-table-column prop="problemTitle" label="问题名称" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="actionName" v-if="mainTab == '0'" label="所属领域" show-overflow-tooltip>
                        <template #default="{ row }">
                            <div>
                                {{ problemNeighbourhoodMap[row.problemNeighbourhood] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="assignType" v-if="mainTab == '1'" label="移交类别" show-overflow-tooltip>
                        <template #default="{ row }">
                            <div>
                                {{ assignTypeMap[row.assignType] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="limitTime" label="整改时限" width="200">
                        <template #default="{ row }">
                            <div>{{ row.limitBeginTime }} - {{ row.limitEndTime }}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="leadDeptName" label="整改牵头单位" show-overflow-tooltip> </el-table-column>
                    <el-table-column prop="problemNatureString" label="问题性质"> </el-table-column>
                    <el-table-column prop="problemStatus" label="操作状态" :show-overflow-tooltip="false">
                        <template #default="{ row }">
                            <div>
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '0'"
                                    slot="reference"
                                    >• 草稿</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '1'"
                                    slot="reference"
                                    >• 待分类</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '2'"
                                    slot="reference"
                                    >• 分类驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '3'"
                                    slot="reference"
                                    >• 待评估</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '4'"
                                    slot="reference"
                                    >• 评估驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '5'"
                                    slot="reference"
                                    >• 待审核</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '6'"
                                    slot="reference"
                                    >• 审核驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '7'"
                                    slot="reference"
                                    >• 已评估</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '8'"
                                    slot="reference"
                                    >• 上级驳回</span
                                >
                                <span
                                    :class="['status', 'status' + row.problemStatus]"
                                    v-if="row.problemStatus == '10'"
                                    slot="reference"
                                    >• 审核中</span
                                >
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="problemRiskLevel" label="风险等级" :show-overflow-tooltip="false">
                        <template #default="{ row }">
                            <div>
                                {{ problemRiskLevelMap[row.problemRiskLevel] }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="orgNum" v-if="mainTab == '1'" label="问题分类">
                        <template #default="{ row }">
                            <div>
                                {{ problemTypeMap[row.problemType] }}
                            </div>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="orgNum" v-if="mainTab == '1'" label="问题评估"></el-table-column> -->
                    <el-table-column label="操作" width="">
                        <template #default="{ row }">
                            <el-button
                                type="text"
                                size="small"
                                v-if="mainTab == '0' && userBtns.evaluate"
                                @click="editItem(row, '问题评估')"
                                >评估</el-button
                            >
                            <el-button type="text" size="small" v-if="mainTab == '1'" @click="editItem(row, '查看问题')"
                                >查看</el-button
                            >
                        </template>
                    </el-table-column>
                </el-table>
                <div class="pagination">
                    <el-pagination
                        v-model:currentPage="query.page"
                        v-model:page-size="query.size"
                        :page-sizes="[15, 30, 50, 100]"
                        background
                        layout="total, sizes, prev, pager, next, jumper"
                        :total="total"
                        @size-change="getList"
                        @current-change="getList"
                    >
                    </el-pagination>
                </div>
            </div>
        </main>
    </div>
</template>
<script setup>
import { ref, reactive, computed, watch, onMounted, onUnmounted, getCurrentInstance } from 'vue';
import axios from 'axios';
import $store from '@/store';
import { useRouter, useRoute } from 'vue-router';
import { ElMessage } from 'element-plus';
import { useStore } from 'vuex';

const { proxy } = getCurrentInstance();
let store = useStore();
const roles = store.state.user.roles;
function isDisables(role) {
    let res = true;
    role.forEach((e) => {
        if (roles.indexOf(e) > -1) {
            res = false;
        }
        return res;
    });
    return res;
}
let mainTab = ref('1');
const router = useRouter();
const route = useRoute();
const emit = defineEmits(['close']);
const props = defineProps({});
let query = ref({
    page: 1,
    size: 15,
    problemLevel: 0,
    problemTitle: '',
    problemVerifyType: 2,
});
let total = ref(0);
const options = [
    {
        value: null,
        label: '全部',
    },
    {
        value: 1,
        label: '已提交',
    },
    {
        value: 0,
        label: '草稿',
    },
];
const problemNatureMap = {
    1: '初发偶发',
    2: '多发问题',
    3: '突发问题',
    4: '历史遗留',
    5: '多跨疑难',
    6: '一般性问题',
};
let assignTypeMap = {
    1: '申请准入“七张问题清单”应用蓄水池',
    2: '申请准入上级部门全量问题库',
    3: '交办整改责任单位问题整改',
};
let problemStatusMap = ['草稿', '待分类', '分类驳回', '待评估', '评估驳回', '待审核', '审核驳回', '已评估', '上级驳回'];
let problemRiskLevelMap = {
    1: '低',
    2: '中',
    3: '高',
};
let problemNeighbourhoodMap = {
    1: '民主党派',
    2: '无党派人士和党外知识分子',
    3: '民族',
    4: '宗教',
    5: '非公有制经济',
    6: '新的社会阶层',
    7: '出国和归国留学生',
    8: '港澳台侨',
};

let problemTypeMap = {
    1: '政治安全',
    2: '经济安全',
    3: '社会安全',
    4: '境外利益安全',
    5: '统战自身建设',
};
let tableData = ref([]);
function fillItem(row, name) {
    router.push({
        name: 'proAssessmentAdd',
        query: {
            title: name,
            type: 1,
            id: row.unitId,
        },
    });
}
function editItem(row, name) {
    router.push({
        name: 'proAssessmentAdd',
        query: {
            title: name,
            tab: mainTab.value,
            id: row ? row.problemId : null,
        },
    });
}
function openDetail(row) {
    router.push({
        path: '/fillTask',
        query: {
            title: '查看',
            id: row.unitId,
        },
    });
}
function dblclickRow(row) {
    if (mainTab.value == 1) {
        fillItem(row);
    } else {
        openDetail(row);
    }
}
function getList() {
    // query.value.selectType = '';
    // query.value.statuses = '';
    // if (mainTab.value == 2) {
    //     query.value.selectType = 2;
    //     query.value.statuses = '2,3';
    // } else {
    //     query.value.selectType = 1;
    //     query.value.statuses = 2;
    // }
    query.value.myVerified = mainTab.value;
    let method = mainTab.value == 0 ? 'toBeEvaluated' : 'evaluated';
    proxy.$api.proAssessment[method](query.value).then((res) => {
        if (res.code == 200) {
            tableData.value = res.data.records;
            total.value = res.data.total;
        }
    });
}
function search() {
    query.value.page = 1;
    getList();
}
const mainTabClick = (vm) => {
    mainTab.value = vm.props.name;

    query.value.myVerified = vm.props.name;
    getList();
    router.replace({
        query: { tab: vm.props.name },
    });
};
function resize() {
    query.value = {
        page: 1,
        size: 15,
        problemLevel: 0,
        problemTitle: '',
        problemVerifyType: 2,
    };
    getList();
}

// 获取按钮权限
const userBtns = computed(() => {
    return store.state.user.userBtns;
});
//获取subMenu权限
const subMenu = computed(() => {
    return store.state.user.sub_menu;
});
watch(() => {});
onMounted(() => {
    mainTab.value = route.query.tab || '0';
    getList();
});
onUnmounted(() => {});
</script>
<style lang="less" scoped>
.taskExecution {
    height: calc(100% - 32px);
    header {
        height: 128px;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        padding: 16px 24px 0 24px;
        box-sizing: border-box;
        .searchs {
            display: flex;
            justify-content: space-between;
        }
        .tabs {
            display: flex;
            height: 50px;
            padding-bottom: 12px;
        }
        .btns {
            white-space: nowrap;
        }
    }
    main {
        margin-top: 16px;
        height: calc(100% - 144px);
        padding: 16px 24px 25px 24px;
        box-sizing: border-box;
        background: #ffffff;
        box-shadow: 0 0 10px -6px rgba(0, 0, 0, 0.3);
        border-radius: 2px;
        .subToolbar {
            height: 50px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            overflow: hidden;
        }
        .table {
            height: calc(100% - 92px);
            .table-header {
                height: calc(100% - 40px);
            }
            .pagination {
                height: 40px;
                padding-top: 10px;
                background: #fff;
                text-align: right;
                padding-right: 24px;
            }
        }
        /deep/.el-table th.el-table__cell {
            background: #f6f8fa;
        }
    }
    .status {
        display: inline-block;
        width: 68px;
        height: 24px;
        text-align: center;
        line-height: 24px;
        &.status2 {
            background: rgba(100, 183, 255, 0.2);
            color: #1492ff;
        }
        &.status3 {
            background: rgba(68, 203, 87, 0.2);
            color: #44cd40;
        }
        &.status1 {
            background: rgba(255, 176, 81, 0.2);
            color: #ff8b00;
        }
        &.status4 {
            background: rgba(255, 127, 127, 0.2);
            color: #ff5c5c;
        }
        &.status0 {
            background: rgba(216, 216, 216, 0.2);
            color: #999999;
        }
        &.status5 {
            background: rgba(85, 230, 193, 0.2);
            color: rgb(85, 230, 193);
        }
        &.status6 {
            background: rgba(179, 55, 113, 0.2);
            color: rgb(179, 55, 113);
        }
        &.status7 {
            background: rgba(189, 197, 129, 0.2);
            color: rgb(189, 197, 129);
        }
    }
}
</style>
